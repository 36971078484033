import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class CatalogGroupRepository extends BaseRepository {
  constructor() {
    super('catalog-group');
  }

  getCatalogGroups({
    city,
    latitude,
    longitude,
    flightIds,
    productIds,
    travelerId,
  }) {
    return authAxios.get(this.resource, {
      params: {
        city,
        latitude,
        longitude,
        'flight-ids': flightIds,
        'product-ids': productIds,
        travelerId,
      },
    });
  }

  getCatalog({ productIds }) {
    return authAxios.get(this.resource, {
      params: {
        'product-ids': productIds,
      },
    });
  }
}
