import store from '../../store/index';

export const getItemInfo = (id, type = null) => {
  if (!id) return;
  let itemDetails;
  switch (type) {
    case 'advica':
      itemDetails = store.state['advica'].details.value;
      break;
    case 'booking':
      itemDetails = store.state[`product/${id}`].details.value;
      break;
    case 'transportation':
      itemDetails = store.state[`transportation/${id}`].details.value;
      break;
    case 'dining':
      itemDetails = store.state[`dining/${id}`].diningDetails.value;
      break;
    default:
      itemDetails = null;
  }
  if (!!itemDetails) {
    let price =
      type === 'booking'
        ? itemDetails.priceStartingAt.value
        : itemDetails.priceScale;
    return {
      name: itemDetails.title,
      sub_title: itemDetails.subTitle,
      categories: itemDetails.categories,
      sub_categories: itemDetails.subCategories,
      price: price,
      id,
    };
  }
};

export const getBookingItemInfo = (data) => {
  const { productId, date, option, price, passes, type } = data;
  let passesObject = {};
  let totalTravelers = 0;
  passes.forEach((item) => {
    passesObject[item.title?.toLowerCase()] = item.value;
    totalTravelers += item.value;
  });
  return {
    ...getItemInfo(productId, type),
    selected_date: date,
    option,
    passes: passesObject,
    total_travelers: totalTravelers,
    price,
  };
};

export const formatFlightInfo = (flights) => {
  if (flights && flights.length) {
    return flights.map((flight) => ({
      flightNumber: flight.flightNumber,
      origin: flight.origin.city,
      destination: flight.destination.city,
      arrrivalTime: flight.arrrivalTime,
      departureTime: flight.departureTime,
    }));
  }
};
