import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class CategoryRepository extends BaseRepository {
  constructor() {
    super('category');
  }

  getCategories({ purchaseStrategy }) {
    return authAxios.get(this.resource, {
      params: {
        purchaseStrategy,
      },
    });
  }
}
