<template>
  <v-container class="page-section" fluid>
    <v-row justify="center" no-gutters>
      <v-col cols="auto">
        <h2 class="page-section-title text-center">
          <slot name="title"></slot>
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col cols="auto">
        <p class="page-section-subtitle text-center">
          <slot name="subtitle"></slot>
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageSection',
};
</script>

<style scoped lang="scss">
.page-section {
  padding-top: 0;
  padding-bottom: 0;

  &-title {
    color: var(--v-grey8-base);
    font-size: 24px;
    font-weight: 300;

    @media (min-width: map-get($grid-breakpoints, md)) {
      font-size: 48px;
    }
  }
  &-subtitle {
    color: var(--v-grey7-base);
    font-size: 14px;
    font-weight: 400;

    @media (min-width: map-get($grid-breakpoints, md)) {
      font-size: 20px;
    }
  }
}
</style>
