import qs from 'qs';
import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class SearchRepository extends BaseRepository {
  constructor() {
    super('search/api');
  }

  getGlobalSearch(text = '', categories = [], flightId = '') {
    const queryString = qs.stringify(
      {
        text: text,
        category: categories,
        flight_ids: flightId,
      },
      { arrayFormat: 'comma' }
    );
    return authAxios.get(
      this.getBaseResourceUrl({
        endpoint: `/recommender/global/search/?${queryString}`,
      })
    );
  }

  // global search for buy on board
  getGlobalSearchBOB(text, flightId) {
    return this.getGlobalSearch(text, [5], flightId);
  }
}
