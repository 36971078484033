import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';
import GlobalComponents from './plugins/GlobalComponents';
import { StripePlugin } from './plugins/StripePlugin';
import './core/filters';
import 'leaflet/dist/leaflet.css';
import VueGtm from 'vue-gtm';
import environment from '@/core/utils/environment.js';
import VueGeolocation from 'vue-browser-geolocation';
import VueTheMask from 'vue-the-mask';
import { i18n } from './plugins/i18n';
import vueCustomElement from 'vue-custom-element';
import CountryFlag from 'vue-country-flag';
import Router from 'vue-router';

Vue.use(VueTheMask);

Vue.config.productionTip = false;
Vue.use(VueGeolocation);
Vue.component('vue-country-flag', CountryFlag);

Vue.use(VueGtm, {
  id: 'GTM-M4X2575',
  enabled: false,
});
Vue.use(vueCustomElement);

GlobalComponents.install(Vue);
StripePlugin.install(Vue);

// start Resolve problem with NavigationDuplicated: Avoided redundant navigation to current location
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
// end

const app = new Vue({
  router,
  vuetify,
  store,
  i18n,
  beforeCreate() {
    if (this.configs == null) {
      this.$store.dispatch('loadConfigs', { vm: this });
    } else {
      this.$store.dispatch('initConfigs', { vm: this });
    }
    this.$gtm.enable(true);
  },
  computed: {
    configs() {
      return this.$store.state.configs;
    },
  },
  watch: {
    configs(configs) {
      const { theme } = configs;
      for (let color in theme) {
        this.$vuetify.theme.themes.light[color] = theme[color];
      }
    },
  },
  render: (h) => h(App),
}).$mount('#app');
