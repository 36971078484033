import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class PlaceRepository extends BaseRepository {
  constructor({ resourceId } = { resourceId: '/' }) {
    super('place', resourceId);
  }

  getPlaces({
    latitude,
    longitude,
    radius,
    minPriceScale,
    maxPriceScale,
    minRating,
    placeTypes,
    tags,
    skip,
    take,
  }) {
    return authAxios.get(this.resource, {
      params: {
        latitude,
        longitude,
        radius,
        'min-price-scale': minPriceScale,
        'max-price-scale': maxPriceScale,
        'min-rating': minRating,
        'place-types': placeTypes,
        tags,
        skip,
        take,
      },
    });
  }

  getDetails({ placeId = this.resourceId, longitude, latitude }) {
    return authAxios.get(this.getBaseResourceUrl({ id: placeId }), {
      params: { longitude, latitude },
    });
  }
}
