import { SIDECAR_API_PATH } from '../../../sidecar-env';

export default class BaseRepository {
  constructor(resource, resourceId) {
    this.resource = resource;
    this.resourceId = resourceId;
  }

  set resource(res) {
    if (!res || typeof res !== 'string')
      throw new Error(
        'Cannot initialize BaseRepository without resource string',
        res
      );
    this._resource = this.startWithSlash(res);
  }

  set resourceId(res) {
    if (typeof res == 'string') this._resourceId = this.startWithSlash(res);
  }

  get resource() {
    return this._resource;
  }
  get resourceId() {
    return this._resourceId;
  }

  startWithSlash(val) {
    if (!val) return '';
    else if (val.startsWith('/')) return val;
    else return `/${val}`;
  }

  getBaseResourceUrl({ id = this.resourceId, endpoint } = {}) {
    const url =
      !!id && id !== '/'
        ? this.resource +
          this.startWithSlash(id) +
          this.startWithSlash(endpoint)
        : this.resource + this.startWithSlash(endpoint);
    return url;
  }
}
