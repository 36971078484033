<script>
import Hotjar from 'vue-hotjar';
import Vue from 'vue';

export default {
  name: 'HotJarMixins',
  methods: {
    createHotJarInstance() {
      Vue.use(Hotjar, {
        id: '2573742',
      });
    },

    pushHotJarAnalytics(event) {
      window.hj =
        window.hj ||
        function() {
          (hj.q = hj.q || []).push(arguments);
        };
      hj('event', event);
    },
  },
};
</script>
