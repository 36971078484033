const equals = (array1, array2) => {
  let isEqual = false;
  if (
    Array.isArray(array1) &&
    Array.isArray(array2) &&
    array1.length === array2.length
  ) {
    isEqual = true;
    for (let index = 0; index < array1.length; index++) {
      if (array1[index] !== array2[index]) {
        isEqual = false;
        break;
      }
    }
  }
  return isEqual;
};

const setAsArray = (obj) => {
  if (!obj) return null;
  if (Array.isArray(obj)) {
    return obj;
  } else return [obj];
};

const updateArrayCount = (array, count, newObj = {}) => {
  const currentCount = array.length;
  let diff = count - currentCount;
  if (diff >= 0) {
    for (let i = 0; i < diff; i++) {
      array.push({ ...newObj });
    }
  } else {
    array = array.slice(0, diff);
  }
  return array;
};

export default {
  equals,
  setAsArray,
  updateArrayCount,
};
