import BookingRepository from './BookingRepository';
import AdvicaRepository from '@/core/repositories/AdvicaRepository';
import OrderRepository from './OrderRepository';
import CatalogGroupRepository from './CatalogGroupRepository';
import FlightRepository from './FlightRepository';
import ShopRepository from './ShopRepository';
import CategoryRepository from './CategoryRepository';
import GroundTransportationRepository from './GroundTransportationRepository';
import LoyaltyRepository from '@/core/repositories/LoyaltyRepository';
import TravelerRepository from '@/core/repositories/TravelerRepository';
import CatalogCustomSearchRepository from '@/core/repositories/CatalogCustomSearchRepository';
import PlaceRepository from '@/core/repositories/PlaceRepository';
import ConciergeRepository from '@/core/repositories/ConciergeRepository';
import InformationRepository from '@/core/repositories/InformationRepository';
import SearchRepository from '@/core/repositories/SearchRepository';
import ConciergeV2Repository from '@/core/repositories/ConciergeV2Repository';

export const repositories = {
  booking: new BookingRepository(),
  advica: new AdvicaRepository(),
  order: new OrderRepository(),
  catalogGroup: new CatalogGroupRepository(),
  flight: new FlightRepository(),
  category: new CategoryRepository(),
  transportation: new GroundTransportationRepository(),
  loyalty: new LoyaltyRepository(),
  traveler: new TravelerRepository(),
  customSearch: new CatalogCustomSearchRepository(),
  place: new PlaceRepository(),
  concierge: new ConciergeRepository(),
  shop: new ShopRepository(),
  information: new InformationRepository(),
  search: new SearchRepository(),
};

const repositoryClasses = {
  booking: (args) => new BookingRepository(args),
  advica: (args) => new AdvicaRepository(args),
  order: (args) => new OrderRepository(args),
  catalogGroup: (args) => new CatalogGroupRepository(args),
  flight: (args) => new FlightRepository(args),
  category: (args) => new CategoryRepository(args),
  transportation: (args) => new GroundTransportationRepository(args),
  loyalty: (args) => new LoyaltyRepository(args),
  traveler: (args) => new TravelerRepository(args),
  customSearch: (args) => new CatalogCustomSearchRepository(args),
  place: (args) => new PlaceRepository(args),
  concierge: (args) => new ConciergeRepository(args),
  conciergeV2: (args) => new ConciergeV2Repository(args),
  shop: (args) => new ShopRepository(args),
  information: (args) => new InformationRepository(args),
  search: (args) => new SearchRepository(args),
};

export const getRepoKey = (repoTypeName, resourceId) =>
  resourceId ? `${repoTypeName}/${resourceId}` : `${repoTypeName}`;

export const initRepo = (repoTypeName, { resourceId }) => {
  const Repo = repositoryClasses[repoTypeName];
  const repoKey = getRepoKey(repoTypeName, resourceId);
  if (Repo) {
    repositories[repoKey] = Repo({ resourceId });
    return repositories[repoKey];
  } else {
    throw new Error(
      `Repository name does not exists. Is "${repoTypeName}" the correct name? Please make sure it is defined or referenced as defined in the RepositoryFactory.`
    );
  }
};

export const RepoFactory = {
  get: (repoTypeName, { resourceId } = {}) => {
    const repoKey = getRepoKey(repoTypeName, resourceId);
    const repo = repositories[repoKey];
    if (repo) return repo;
    else {
      return initRepo(repoTypeName, { resourceId });
    }
  },
};
