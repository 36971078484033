import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class FlightRepository extends BaseRepository {
  constructor({ resourceId } = { resourceId: '/' }) {
    super('flight', resourceId);
    this.flightPrefixRestriction = null;
  }

  setFlightRestriction({
    pattern,
    error = 'Cannot get flight; flight Prefix Restriction does not match',
  }) {
    this.flightPrefixRestriction = { pattern: RegExp(pattern, 'i'), error };
  }

  getFlight(resourceId = this.resourceId) {
    return authAxios.get(this.getBaseResourceUrl({ id: resourceId }));
  }

  getFlights({
    flightNumber,
    departureDate,
    withFlightPreorderAvailability,
    searchWithCodeShareFlightNumber,
  }) {
    if (flightNumber == null)
      throw new Error('Cannot get flight; missing flightNumber');
    if (departureDate == null)
      throw new Error('Cannot get flight; missing departureDate');
    if (
      Boolean(this.flightPrefixRestriction) &&
      !this.flightPrefixRestriction.pattern?.test(flightNumber)
    ) {
      throw new Error(this.flightPrefixRestriction.error);
    }
    const parsedDepartureDate = /\d{4}-(0?[1-9]|1[012])-([12][0-9]|3[01]|0?[1-9])*/.exec(
      departureDate
    );

    if (parsedDepartureDate !== null) {
      departureDate = parsedDepartureDate[0];
    }
    return authAxios
      .get(this.resource, {
        params: {
          'departure-date': departureDate,
          'flight-number': flightNumber,
          'store-status': withFlightPreorderAvailability,
          'get-code-share-operator': searchWithCodeShareFlightNumber,
        },
      })
      .catch((e) => {
        return e && e.response ? e.response : `Error loading flight`;
      });
  }

  async getMultiFlights(flights) {
    if (this.flightPrefixRestriction) {
      flights = flights.filter((flight) =>
        this.flightPrefixRestriction.pattern?.test(flight.flightNumber)
      );
    }
    const promises = await flights.map((flight) => this.getFlights(flight));
    return Promise.all(promises).then((responses) => {
      return responses.map((flightInfo) => {
        if (flightInfo && flightInfo.data && flightInfo.data.length > 0) {
          return flightInfo.data[0];
        } else {
          if (flightInfo?.data?.errorMessage) {
            return { error: { message: flightInfo.data.errorMessage } };
          } else {
            return { error: { message: 'An error occurred.' } };
          }
        }
      });
    });
  }
}
