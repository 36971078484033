import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class ShopRepository extends BaseRepository {
  constructor({ resourceId } = { resourceId: '/' }) {
    super('store', resourceId);
  }

  getStores(flightIds = []) {
    const url = `${this.resource}${this.storeQueryParams(flightIds)}`;
    return authAxios.get(url).then((response) => response.data);
  }

  getCategories() {
    const url = this.getBaseResourceUrl();
    return authAxios.get(url).then((response) => response.data);
  }

  getTagGroups(listings) {
    /**
     * @param listings: Array of 'offeringTypeId's from getCategoryCategories response
     */
    const url = this.getBaseResourceUrl({
      id: this.resourceId,
      endpoint: 'offerings',
    });
    return authAxios
      .get(url + this.offeringTypeQueryParams(listings), {
        params: {
          'max-results': 200,
        },
      })
      .then((r) => ({
        ...r.data,
        offerings: r.data.offerings,
      }));
  }

  getOffering(id) {
    const url = this.getBaseResourceUrl({
      id: this.resourceId,
      endpoint: `offering/${id}`,
    });
    return authAxios.get(url).then((response) => response.data);
  }

  getStoreStatus(id) {
    const url = this.getBaseResourceUrl({
      id: this.resourceId,
      endpoint: 'status',
    });
    return authAxios.get(url).then((response) => response.data);
  }

  storeQueryParams(flightIds) {
    return flightIds.reduce(
      (a, b, i) => a + `${i == 0 ? '?' : '&'}flight-ids=${b}`,
      ''
    );
  }

  offeringTypeQueryParams(listings) {
    return listings.reduce(
      (a, b, i) => a + `${i == 0 ? '?' : '&'}offering-type-ids=${b}`,
      ''
    );
  }
}
