import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
const deviceIdKey = 'glx-device-id';
const tokenKey = 'glx-token';
const themesKey = 'glx-themes';
const glxStripe = 'glx-stripe-key';
const glxFlights = 'glx-flights';

const getDeviceId = () => {
  const deviceId = localStorage.getItem(deviceIdKey);
  if (!deviceId) setDeviceId(uuidv4());
  return localStorage.getItem(deviceIdKey);
};

const setThemes = (themes) => {
  localStorage.setItem(themesKey, JSON.stringify(themes));
};

const getThemes = () => {
  return JSON.parse(localStorage.getItem(themesKey));
};

const setDeviceId = (deviceId) => {
  localStorage.setItem(deviceIdKey, deviceId);
};

const getToken = () => {
  return JSON.parse(localStorage.getItem(tokenKey));
};

const setToken = (token) => {
  localStorage.setItem(tokenKey, JSON.stringify(token));
};

const removeToken = () => {
  localStorage.removeItem(tokenKey);
};

const setFlights = (flights) => {
  localStorage.setItem(glxFlights, JSON.stringify(flights));
};

const getFlights = () => {
  return JSON.parse(localStorage.getItem(glxFlights));
};

const removeFlights = () => {
  localStorage.removeItem(glxFlights);
};

const getStripeKey = (stripeKey) => {
  return localStorage.getItem(glxStripe);
};

const setStripeKey = (stripeKey) => {
  localStorage.setItem(glxStripe, stripeKey);
};

const getLocale = () => {
  return Cookies.get('i18n_redirected');
};

const setLocale = (locale) => {
  return Cookies.set('i18n_redirected', locale);
};

export default {
  getDeviceId,
  setDeviceId,
  getToken,
  setToken,
  removeToken,
  setStripeKey,
  getStripeKey,
  setThemes,
  getThemes,
  setFlights,
  getFlights,
  removeFlights,
  getLocale,
  setLocale,
};
