import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class InformationRepository extends BaseRepository {
  constructor() {
    super('information');
  }

  getInformationModuleItems(id) {
    let endpoint = `/information/article/${id}`;

    return authAxios.get(
      this.getBaseResourceUrl({
        endpoint,
      })
    );
  }

  getArticles(queryString) {
    let endpoint = '/information/';
    if (queryString) {
      endpoint = endpoint + '?' + queryString;
    }
    return authAxios.get(
      this.getBaseResourceUrl({
        endpoint,
      })
    );
  }
}
