import Vue from 'vue';

export const currencyFilter = (value, type = 'USD') => {
  if (value || value === 0) {
    const options = {
      style: 'currency',
      currency: type,
    };
    return advancedCurrencyFilter(value, options);
  } else {
    return value;
  }
};

export const advancedCurrencyFilter = (value, options) => {
  if (value || value === 0) {
    return new Intl.NumberFormat('en-UK', options).format(value);
  } else {
    return value;
  }
};

export const currencyTextFormatter = (baseCurrency) => {
  const options = {
    style: 'currency',
    currency: baseCurrency,
  };
  const currencySymbol = new Intl.NumberFormat('en-UK', options)
    .formatToParts(0)
    .filter((c) => c.type === 'currency');

  if (currencySymbol.length > 0) {
    return `${currencySymbol[0].value}${baseCurrency}`; // $USD
  } else {
    return baseCurrency;
  }
};

export default Vue.filter('currency', currencyFilter);
