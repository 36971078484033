import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class GroundTransportationRepository extends BaseRepository {
  constructor({ resourceId } = { resourceId: '/' }) {
    super('transportation', resourceId);
  }

  getRides(
    pollId,
    {
      startLocation,
      endLocation,
      startTime,
      passengers,
      polling,
      minPrice,
      maxPrice,
      currency,
      vehicleSize,
    }
  ) {
    return authAxios.get(
      this.getBaseResourceUrl({
        endpoint: `poll/${pollId}`,
      }),
      {
        params: {
          'start-location': startLocation,
          'end-location': endLocation,
          'start-time': startTime,
          passengers: passengers,
          polling: polling,
          'min-price': minPrice,
          'max-price': maxPrice,
          currency: currency,
          'vehicle-size': vehicleSize,
        },
      }
    );
  }

  getPollingInfo({
    startLocation,
    endLocation,
    startTime,
    passengers,
    polling,
    minPrice,
    maxPrice,
    currency,
    vehicleSize,
  }) {
    return authAxios.get(this.resource, {
      params: {
        'start-location': startLocation,
        'end-location': endLocation,
        'start-time': startTime,
        passengers: passengers,
        polling: polling,
        'min-price': minPrice,
        'max-price': maxPrice,
        currency: currency,
        'vehicle-size': vehicleSize,
      },
    });
  }

  getDetails(transportationId = this.resourceId) {
    return authAxios.get(this.getBaseResourceUrl({ id: transportationId }));
  }

  getQuestions(transportationId = this.resourceId) {
    return authAxios.get(
      this.getBaseResourceUrl({ id: transportationId, endpoint: `question` })
    );
  }
}
