const validateOrThrow = (flight, name) => {
  if (!flight) throw new Error(`Cannot evaluate ${name} without flight`);
};

const isCurrentFlightAvailableForPreorder = (flight) => {
  validateOrThrow(flight, 'isCurrentFlightAvailableForPreorder');
  return !(
    isPreorderAvailableSoon(flight) ||
    isPreorderUnavailable(flight) ||
    hasPreorderEnded(flight)
  );
};

const isPreorderAvailableSoon = (flight) => {
  validateOrThrow(flight, 'isPreorderAvailableSoon');

  const { storeCatalogOpeningTime, isStoreCatalogAvailableNow } = flight;
  const openingDate = new Date(storeCatalogOpeningTime).getTime();
  const dateNow = Date.now();

  return (
    !isStoreCatalogAvailableNow &&
    storeCatalogOpeningTime !== null &&
    openingDate > dateNow
  );
};

const isPreorderUnavailable = (flight) => {
  validateOrThrow(flight, 'isPreorderUnavailable');

  let {
    storeCatalogOpeningTime,
    storeCatalogClosingTime,
    isStoreCatalogAvailableNow,
  } = flight;

  return (
    !isStoreCatalogAvailableNow &&
    storeCatalogOpeningTime === null &&
    storeCatalogClosingTime === null
  );
};

const hasPreorderEnded = (flight) => {
  validateOrThrow(flight, 'hasPreorderEnded');
  let { storeCatalogClosingTime, isStoreCatalogAvailableNow } = flight;
  let closingDate = new Date(storeCatalogClosingTime).getTime();
  let dateNow = Date.now();

  return (
    !isStoreCatalogAvailableNow &&
    storeCatalogClosingTime !== null &&
    closingDate < dateNow
  );
};

const appendFlightInfoToURL = (url, flight) => {
  if (!url) throw new Error('Argument url cannot be empty');
  validateOrThrow(flight, 'appendFlightInfoToURL');
  if (url.constructor.name !== 'URL') {
    throw new Error('Argument url must be of type URL');
  }
  const urlCopy = new URL(url.href);
  const departureDate = flight.departureTime.split('T')[0];

  urlCopy.searchParams.append('flightNumber', flight.flightNumber);
  urlCopy.searchParams.append('flightDate', departureDate);
  return urlCopy;
};

export {
  hasPreorderEnded,
  isPreorderAvailableSoon,
  isCurrentFlightAvailableForPreorder,
  isPreorderUnavailable,
  appendFlightInfoToURL,
};
