import axios from 'axios';
import localstorageUtils from '../utils/localstorage';
import axiosRetry from 'axios-retry';
import qs from 'qs';
import { RepoFactory } from './RepositoryFactory';
import { setupCache } from 'axios-cache-interceptor';

import { SIDECAR_API_PATH } from '../../../sidecar-env';
import AuthService from '@/core/auth/AuthService';

let baseURL = window.location.origin + SIDECAR_API_PATH;
baseURL += baseURL.endsWith('/') ? 'api/' : '/api/';
let paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'repeat' });
};

const axiosInstance = axios.create({
  baseURL,
  paramsSerializer,
});
const authAxios = setupCache(axiosInstance);
axiosRetry(authAxios, { retries: 3, baseURL });

const tokenInterceptor = async (config) => {
  const localTokenObj = localstorageUtils.getToken();
  if (localTokenObj === null || !localTokenObj.hasOwnProperty('token')) {
    /* eslint-disable no-console */
    console.warn('Cannot append auth header: token not found');
    return config;
  }
  config.headers = { ...config.headers, ...getHeaders(localTokenObj.token) };
  return config;
};

const localeInterceptor = (config) => {
  const locale = localstorageUtils.getLocale();
  if (locale) config.headers['Accept-Language'] = locale;
  return config;
};

const unauthorisedInterceptor = async (error) => {
  const status = error.response ? error.response.status : null;

  if (status === 401) {
    let authService = new AuthService();
    await authService.authenticate();
    error.config.headers['Authorization'] = 'Bearer ' + authService.token;
    return authAxios.request(error.config);
  }

  return Promise.reject(error);
};

authAxios.interceptors.request.use(tokenInterceptor);
authAxios.interceptors.request.use(localeInterceptor);
authAxios.interceptors.response.use(
  (response) => response,
  unauthorisedInterceptor
);

const getHeaders = (token, deviceId = getDeviceId()) => {
  const headers = {
    'x-device-id': deviceId,
    'x-os-version': navigator.appName,
    'x-language': 'en',
    'x-locale': 'en-US',
    'x-region': 'CA',
    'x-application-id': 'web.guestlogix.Traveler',
    'x-timezone': 'UTC',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

const getDeviceId = () => {
  return localstorageUtils.getDeviceId();
};

export { authAxios, getHeaders, RepoFactory, tokenInterceptor };
