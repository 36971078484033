import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class CatalogCustomSearchRepository extends BaseRepository {
  constructor() {
    super('custom-search');
  }

  getCustomSearch({
    text,
    purchaseStrategy,
    flightId,
    city,
    country,
    travelerId,
    latitude,
    longitude,
    sortField,
    sortOrder,
    skip,
    take,
  }) {
    return authAxios.get(this.resource, {
      params: {
        text,
        'purchase-strategy': purchaseStrategy,
        flightId,
        city,
        country,
        travelerId,
        latitude,
        longitude,
        sortField,
        sortOrder,
        skip,
        take,
      },
    });
  }
}
