<script>
import { loadLanguageAsync } from '@/plugins/i18n';
import localstorageUtils from '@/core/utils/localstorage';
import * as Sentry from '@sentry/vue';

export default {
  name: 'LanguageMixins',
  computed: {
    userPreferredLanguage() {
      return localstorageUtils.getLocale();
    },
  },

  watch: {
    ['$i18n.locale']: {
      immediate: true,
      handler(newValue, oldValue) {
        Sentry.setTag('page_locale', newValue);
        this.$stripe.updateLocale(newValue);
      },
    },
  },

  methods: {
    switchUserLocale(lang) {
      // explicitly change user preferred language
      // and save user preference
      localstorageUtils.setLocale(lang);
      location.reload();
    },

    getNavigatorLanguage() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return (
          navigator.userLanguage ||
          navigator.language ||
          navigator.browserLanguage ||
          'en'
        );
      }
    },

    async updateDefaultLanguage(languages) {
      if (this.userPreferredLanguage) {
        return await loadLanguageAsync(this.userPreferredLanguage);
      } else if (languages?.length > 1) {
        await this.selectLanguage(languages);
      } else if (languages?.length > 0) {
        await loadLanguageAsync(languages[0].locale.replace('_', '-'));
      }
    },

    async selectLanguage(languages) {
      const availableConfigsLanguageLocales = languages.map((lang) =>
        lang.locale.replace('_', '-')
      );
      let foundLanguage = languages.find((lang) => lang.default);
      for (const language of navigator.languages) {
        const languageIndex = availableConfigsLanguageLocales.indexOf(language);
        if (languageIndex > -1) {
          foundLanguage = languages[languageIndex];
          break;
        }
      }

      if (foundLanguage && foundLanguage.locale !== this.$i18n.locale) {
        await loadLanguageAsync(foundLanguage.locale.replace('_', '-'));
      }
    },
  },
};
</script>
