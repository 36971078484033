import { authAxios } from './index';
import BaseRepository from './BaseRepository';
import moment from 'moment';

export default class BookingRepository extends BaseRepository {
  constructor({ resourceId } = { resourceId: '/' }) {
    super('booking', resourceId);
  }

  getDetails({ bookingId = this.resourceId, travelerId }) {
    return authAxios.get(this.getBaseResourceUrl({ id: bookingId }), {
      params: {
        travelerId: travelerId,
      },
    });
  }

  getPasses(
    { bookingId, optionId } = { bookingId: this.resourceId, optionId: '' }
  ) {
    return authAxios.get(
      this.getBaseResourceUrl({ id: bookingId, endpoint: `pass` }),
      {
        params: {
          'option-id': optionId,
        },
      }
    );
  }

  getAvailability({ bookingId = this.resourceId, startDate }) {
    const endDate = moment(startDate)
      .endOf('month')
      .format('YYYY-MM-DD');
    return authAxios.get(
      this.getBaseResourceUrl({ id: bookingId, endpoint: `schedule` }),
      {
        params: {
          from: startDate,
          to: endDate,
        },
      }
    );
  }

  getQuestions({ bookingId = this.resourceId, passIds }) {
    return authAxios.get(
      this.getBaseResourceUrl({ id: bookingId, endpoint: `question` }),
      {
        params: {
          'pass-ids': passIds,
        },
      }
    );
  }

  getBookings({
    city,
    searchText,
    latitude,
    longitude,
    skip,
    take,
    minAvailabilityDate,
    maxAvailabilityDate,
    minPrice,
    maxPrice,
    currency,
    subCategories,
    sortField,
    sortOrder,
    faceMaskRequired,
    socialDistancingEnforced,
    intensifiedCleaning,
  }) {
    return authAxios.get(this.resource, {
      params: {
        city: city,
        text: searchText,
        latitude: latitude,
        longitude: longitude,
        skip: skip,
        take: take,
        'availability-start': minAvailabilityDate,
        'availability-end': maxAvailabilityDate,
        'min-price': minPrice,
        'max-price': maxPrice,
        currency: currency,
        sub_categories: subCategories,
        'sort-field': sortField,
        'sort-order': sortOrder,
        'face-mask-required': faceMaskRequired,
        'social-distancing-enforced': socialDistancingEnforced,
        'intensified-cleaning': intensifiedCleaning,
      },
    });
  }
}
