<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import * as Sentry from '@sentry/vue';
import { transformNavigationMenuItem } from '@/core/utils/navigationConfigHelper';

export default {
  name: 'NavigationMenuConfigMixins',
  mixins: [FlightsMixins],
  computed: {
    isShopAvailable() {
      return (
        this.hasSelectedContextFlight &&
        this.isCurrentFlightAvailableForPreorder()
      );
    },

    disableTooltipMessage() {
      return !this.hasSelectedContextFlight
        ? this.$t('menu.shop-no-flight-tooltip')
        : !this.isCurrentFlightAvailableForPreorder()
        ? this.$t('menu.shop-catalog-unavailable-tooltip')
        : '';
    },

    hamburgerMenuItems() {
      let hamburgerMenu = this.$store.getters[`home/hamburgerMenuItems`] ?? '';
      if (hamburgerMenu === '') return null;
      return this.convertHamburgerItems(hamburgerMenu);
    },

    menuBarItems() {
      let barMenu = this.$store.getters[`home/menuBarItems`] ?? '';
      if (barMenu === '') return null;
      return this.convertMenuItems(barMenu);
    },
  },

  methods: {
    pathPrefix(path) {
      try {
        return this.$pathPrefix(path);
      } catch (e) {
        return path;
      }
    },

    convertHamburgerItems(hamburgerMenuItems) {
      let convertedItems = [];

      for (let i = 0; i < hamburgerMenuItems.length; i++) {
        let menuItems = hamburgerMenuItems[i].links;
        let convertedMenuItems = this.convertMenuItems(menuItems);
        convertedItems[i] = {
          links: convertedMenuItems,
          title: hamburgerMenuItems[i].title,
        };
      }
      return convertedItems;
    },

    convertMenuItems(menuItems) {
      let convertedItems = [];
      for (let i = 0; i < menuItems.length; i++) {
        try {
          let convertMenuItem = transformNavigationMenuItem(menuItems[i], {
            $vue: this,
            contextFlight: this.selectedContextFlight,
          });
          convertedItems.push(convertMenuItem);
        } catch (e) {
          Sentry.setExtras(menuItems[i]);
          Sentry.captureException(e);
        }
      }
      return convertedItems;
    },
  },
};
</script>
