<template>
  <v-sheet
    class="env-banner pa-1"
    v-if="!dismissed"
    :width="220"
    :elevation="2"
  >
    <v-row no-gutters justify="space-around" align="center">
      <v-spacer></v-spacer>
      <v-col>{{ text }}</v-col>
      <v-col cols="auto">
        <v-btn
          text
          small
          icon
          @click="dismiss"
          aria-label="env-banner"
          title="env-banner"
        >
          <v-icon aria-hidden="true">mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: 'EnvBanner',
  props: {
    text: {
      type: String,
      default: 'Sandbox Mode',
    },
  },
  data() {
    return {
      dismissed: false,
    };
  },
  methods: {
    dismiss() {
      this.dismissed = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-sheet.env-banner {
  opacity: 1;
  text-align: center;
  transform: rotate(-45deg);
  top: 15px;
  left: -60px;
  z-index: 10;
  position: fixed;
  opacity: 0.95;
  background-color: white;
  border-bottom: 4px solid var(--v-primary-base);
  .v-btn {
    &:hover {
      color: var(--v-error-base);
    }
  }
}
</style>
