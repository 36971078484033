import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class ConciergeRepository extends BaseRepository {
  constructor(resource = 'concierge') {
    super(resource);
    this.abortController = null;
  }

  getConciergeModuleItems({ flight, date, city, stage, shopV4FF }) {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();

    let endpoint;
    if (!flight && !date) {
      endpoint = `api/v1/concierge/discover/`;
    } else {
      endpoint = !!stage
        ? `api/v1/concierge/${flight.carrier}/${flight.number}/${city}/${date}/${stage}/`
        : `api/v1/concierge/${flight.carrier}/${flight.number}/${city}/${date}/`;
    }

    let params = {};

    if (shopV4FF) {
      params = {
        shopV4FF,
      };
    }

    return authAxios.get(
      this.getBaseResourceUrl({
        endpoint,
      }),
      {
        signal: this.abortController.signal,
        params,
      }
    );
  }

  getConciergeStages({ flight, departureDate, iataCode, shopV4FF }) {
    let params = {};

    if (shopV4FF) {
      params = {
        shopV4FF,
      };
    }

    return authAxios.get(
      this.getBaseResourceUrl({
        endpoint: `api/v1/concierge/${flight.carrier}/${flight.number}/${iataCode}/${departureDate}/stages`,
      }),
      {
        signal: this.abortController.signal,
        params,
      }
    );
  }
}
