<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :multi-line="multiLine"
    :timeout="timeout"
    :top="top"
    height="80"
    width="375"
    :style="conciergeCustomStyle ? 'top: 200px' : ''"
  >
    <p class="mb-0">{{ text }}</p>
    <p v-if="success" class="mb-0">{{ email }}</p>
    <template v-slot:action="{ attrs }">
      <v-btn icon small text v-bind="attrs" @click="snackbar = false">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ToastComponent',
  props: {
    text: {
      type: String,
      default: function() {
        return this.$t('concierge.invalidflightAlert');
      },
    },

    value: {
      type: Boolean,
      default: false,
    },

    success: Boolean,
    email: String,
    multiLine: Boolean,

    top: {
      type: Boolean,
      default: true,
    },

    conciergeCustomStyle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      snackbar: false,
      timeout: 4000,
      color: 'var(--v-grey7-base)',
    };
  },

  created() {
    if (!!this.value) {
      this.snackbar = true;
    }
  },

  watch: {
    value(val) {
      if (val) {
        this.snackbar = true;
      }
    },

    snackbar(val, oldVal) {
      if (!!oldVal && !val) {
        this.$emit('toastClosed');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-snack__wrapper {
  top: 200px !important;
}
</style>
