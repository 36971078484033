<script>
import {
  getItemInfo,
  getBookingItemInfo,
  formatFlightInfo,
} from './tagManagerUtils.js';

export default {
  name: 'GoogleTagManagerMixins',
  methods: {
    pushProductClickAnalytics(
      product,
      category,
      actionFieldType,
      actionFieldValue
    ) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        this.$gtm.trackEvent({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: { [actionFieldType]: actionFieldValue }, // Optional list property.
              products: [
                {
                  name: product.title,
                  id: product.id,
                  category: category,
                },
              ],
            },
          },
        });
      }
    },

    pushProductDetailsViewAnalytics(
      productId,
      type,
      actionFieldType,
      actionFieldValue
    ) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        this.$gtm.trackEvent({
          event: 'productDetailView',
          ecommerce: {
            detail: {
              actionField: { [actionFieldType]: actionFieldValue }, // 'detail' actions have an optional list property.
              products: [getItemInfo(productId, type)],
            },
          },
        });
      }
    },

    pushCheckoutAnalytics(productId, stepNumber, type) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        this.$gtm.trackEvent({
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: { step: stepNumber },
              products: [getItemInfo(productId, type)],
            },
          },
        });
      }
    },

    pushButtonClick(buttonName, category = null) {
      this.$gtm.trackEvent({
        event: 'gtm.element',
        category: category,
        value: buttonName,
      });
    },

    pushAddFlightAnalytics(flights, actionFieldType, actionFieldValue) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        this.$gtm.trackEvent({
          event: 'addFlight',
          ecommerce: {
            booking: {
              actionField: { [actionFieldType]: actionFieldValue },
              flights: [formatFlightInfo(flights)],
            },
          },
        });
      }
    },

    pushTransportationResultTimeoutAnalytics(pageName, category = null) {
      this.$gtm.trackEvent({
        event: 'transportationResultTimeout',
        category,
        value: pageName,
      });
    },

    /* Google Analytics 4 Data Layer Methods

      'items' object is a list of item objects

       Example item object:
      {
        'item_name': 'Donut Friday Scented T-Shirt', // Name or ID is required
        'item_id': '67890',
        'price': '33.75',
        'item_brand': 'Google',
        'item_category': 'Apparel',
        'item_category_2': 'Mens',
        'item_category_3': 'Shirts',
        'item_category_4': 'Tshirts',
        'item_variant': 'Black',
        'item_list_name': 'Search Results',
        'item_list_id': 'SR123',
        'index': 1,
        'quantity': '2'
      }

      Not all parameters are needed, whatever is available, but each item needs to include either Name or ID
      */

    pushProductClick(items) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'select_item',
          ecommerce: {
            items: items,
          },
        });
      }
    },

    pushProductView(items) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'view_item',
          ecommerce: {
            items: items,
          },
        });
      }
    },

    pushCheckAvailabilityAnalytics(items) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({
          event: 'check_availability',
          ecommerce: {
            items: items,
          },
        });
      }
    },

    pushProductBookThisTourAnalytics(data) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({
          event: 'book_this_tour',
          ecommerce: {
            items: [getBookingItemInfo(data)],
          },
        });
      }
    },

    pushProductRideClick(items) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({
          event: 'view_ride',
          ecommerce: {
            items: items,
          },
        });
      }
    },

    pushProductRideSelect(items) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({
          event: 'select_ride',
          ecommerce: {
            items: items,
          },
        });
      }
    },

    pushAddToCart(items) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            items: items,
          },
        });
      }
    },

    pushRemoveFromCart(items) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'remove_from_cart',
          ecommerce: {
            items: items,
          },
        });
      }
    },

    pushBeginCheckout(items) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            items: items,
          },
        });
      }
    },

    pushAdBlockerDetection() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'ad-blocker',
      });
    },

    /* Purchase object consists of
      'purchase': {
        'transaction_id': 'T12345',
        'affiliation': 'Online Store',
        'value': '35.43',
        'tax': '4.90',
        'shipping': '5.99',
        'currency': 'EUR',
        'coupon': 'SUMMER_SALE',
        'items': [{}]
        }

        with items described above
     */
    pushPurchase(purchase) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            purchase,
          },
        });
      }
    },

    pushPlaceOrder(purchase) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'place_order',
          ecommerce: {
            purchase,
          },
        });
      }
    },

    pushExperiencesPurchase(data, orderId) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'experiences_item_purchase',
          ecommerce: {
            transaction_id: orderId,
            items: [getBookingItemInfo(data)],
          },
        });
      }
    },

    pushWelcomePurchase(data, orderId) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'welcome_item_purchase',
          ecommerce: {
            transaction_id: orderId,
            items: [getBookingItemInfo(data)],
          },
        });
      }
    },

    pushDiscountCodeStatus(data, promoCode) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'discount_code_status',
          ecommerce: {
            items: [{ ...getBookingItemInfo(data), status: data.status }],
            promoCode,
          },
        });
      }
    },

    pushAddFlight(flights) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'add_flight',
          flights,
        });
      }
    },

    pushRemoveFlight() {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'remove_flight',
        });
      }
    },

    pushSearchFlight(info) {
      const { flight_number, departure_date } = info;
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'search_flight',
          flight_number,
          departure_date,
        });
      }
    },

    pushFailedSearchFlight(val, info) {
      const { error, flight_number, departure_date } = info;
      if (this.$gtm.enabled()) {
        window.dataLayer.push({
          event: 'search_flight_error',
          error: val,
          flight_number,
          departure_date,
        });
      }
    },

    pushSelectFlight(info) {
      const { flight_number, departure_date } = info;
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'select_flight',
          flight_number,
          departure_date,
        });
      }
    },

    pushSwitchFlight(info) {
      const { flight_number, departure_date } = info;
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'switch_flight',
          flight_number,
          departure_date,
        });
      }
    },

    pushArticleClick(article) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'article_item_click',
          ecommerce: {
            article: article,
          },
        });
      }
    },

    pushDiningItemClickAnalytics(item) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'dining-item-click',
          ecommerce: {
            item: item,
          },
        });
      }
    },

    pushDiningVisitWebsiteAnalytics(productId) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'dining-visit_website-click',
          productId: productId,
        });
      }
    },

    pushProductImpressions(groups) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'view_item_list',
          itemGroups: groups,
        });
      }
    },

    pushDiningSearchButtonClickAnalytics(city, longlat, query) {
      if (!!city || !!query) {
        if (this.$gtm.enabled()) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'dining-search',
            ecommerce: {
              city: city,
              longlat: longlat,
              query: query,
            },
          });
        }
      }
    },

    pushExperiencesSearchByLocationAnalytics(city, longlat) {
      if (!!city) {
        if (this.$gtm.enabled()) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'experiences_location_search',
            ecommerce: {
              city: city,
              longlat: longlat,
            },
          });
        }
      }
    },

    pushExperiencesSearchByKeywordAnalytics(keyword) {
      if (!!keyword) {
        if (this.$gtm.enabled()) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'experiences_keyword_search',
            ecommerce: {
              keyword: keyword,
            },
          });
        }
      }
    },

    pushExperiencesMultiSearchAnalytics(city, longlat, keyword) {
      if (!!city && !!keyword) {
        if (this.$gtm.enabled()) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'experiences_multi_search',
            ecommerce: {
              city: city,
              longlat: longlat,
              keyword: keyword,
            },
          });
        }
      }
    },

    pushWelcomeSearchButtonClickAnalytics(city, longlat) {
      if (!!city) {
        if (this.$gtm.enabled()) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'welcome_location_search',
            ecommerce: {
              city: city,
              longlat: longlat,
            },
          });
        }
      }
    },

    pushTransportationSearchButtonClickAnalytics(
      origin,
      destination,
      date,
      travelers
    ) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'transportation-search',
          ecommerce: {
            origin: origin,
            destination: destination,
            date: date,
            travelers: travelers,
          },
        });
      }
    },

    pushInformationSearchButtonClickAnalytics(city) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'information-search',
          ecommerce: {
            city: city,
          },
        });
      }
    },

    pushFilterAnalytics(path, filterName, selected, unit) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: path + '-' + filterName + '-filter',
          ecommerce: {
            selected,
            unit,
          },
        });
      }
    },

    pushExploreDestinationsAnalytics(event, city) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          ecommerce: {
            city: city,
          },
        });
        window.dataLayer.push({
          event: event,
        });
      }
    },

    pushSeeMoreExperiencesAnalytics(city, category) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'experiences_see_more',
          ecommerce: {
            city: city,
            category: category,
          },
        });
      }
    },

    pushSeeMoreAnalytics(city, category) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'welcome_see_more',
          ecommerce: {
            city: city,
            category: category,
          },
        });
      }
    },

    pushWelcomeItemClickAnalytics(item, category) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'welcome_item_click',
          ecommerce: {
            click: {
              products: [
                {
                  name: item.title,
                  id: item.id,
                  category: category,
                },
              ],
            },
          },
        });
      }
    },

    pushExperiencesItemClickAnalytics(
      product,
      category,
      actionFieldType,
      actionFieldValue
    ) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'experiences_item_click',
          ecommerce: {
            click: {
              actionField: { [actionFieldType]: actionFieldValue }, // Optional list property.
              products: [
                {
                  name: product.title,
                  id: product.id,
                  category: category,
                },
              ],
            },
          },
        });
      }
    },

    pushWelcomeBackgroundLinkAnalytics(city) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'welcome_bg_item_click',
          ecommerce: {
            click: {
              products: [
                {
                  product_name: city.event_name,
                  id: city.event_id,
                  location: city.title,
                },
              ],
            },
          },
        });
      }
    },

    pushJumpLinkClickAnalytics(item) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'dc_jump_link',
          ecommerce: {
            click: {
              products: [
                {
                  name: item.title,
                },
              ],
            },
          },
        });
      }
    },
  },
};
</script>
