<script>
export default {
  name: 'ConfigsMixins',
  computed: {
    configs() {
      return this.$store.state.configs;
    },

    logo() {
      if (this.configs && this.configs.logo) {
        return this.configs.logo;
      } else {
        return '';
      }
    },

    hasMultipleLanguages() {
      return Boolean(this.airlineLanguages?.length > 1);
    },

    airlineLanguages() {
      return this.configs.languages.map((lang) => {
        return lang.locale?.includes('_')
          ? { ...lang, locale: lang.locale.replace('_', '-') }
          : lang;
      });
    },

    defaultCurrency() {
      return this.$store.state['configs'].default_currency || 'USD';
    },

    shopOnlyMode() {
      // If this configuration is on, then all digital-concierge routes + global components will be disabled.
      return typeof this.$store.getters[`shopOnlyMode`] !== 'undefined'
        ? this.$store.state.shopOnlyMode
        : false; //if the setting isn't defined, then it is concierge mode
    },

    termsAndConditionsUrl() {
      return this.configs.terms_and_conditions;
    },

    helpAndFeedbackUrl() {
      return this.configs.help_and_feedback;
    },

    privacyPolicyUrl() {
      return this.configs.privacy_policy;
    },

    heroFlightConfig() {
      return this.$store.getters[`home/heroFlightConfig`];
    },

    restrictionTitle() {
      return (
        this.$store.getters[`home/restrictionTitle`] ??
        this.$t('restriction.title')
      );
    },

    restrictionText() {
      return (
        this.$store.getters[`home/restrictionText`] ??
        this.$t('restriction.message')
      );
    },

    pnrTitle() {
      return this.$store.getters[`home/pnrTitle`] ?? this.$t('shop.pnr.number');
    },

    pnrNote() {
      return (
        this.$store.getters[`home/pnrNote`] ?? this.$t('shop.pnr.your_number')
      );
    },

    seatHelpText() {
      return (
        this.$store.getters[`home/seatHelpText`] ?? this.$t('shop.pnr.message')
      );
    },

    flightsCleanupCriteria() {
      return this.$store.getters[`home/flightsCleanupCriteria`] ?? '30 days';
    },

    heroBackgroundImage() {
      if (this.heroFlightConfig && this.showBackgroundHeroImage) {
        const ctaValue =
          this.$route.query && this.$route.query.cta
            ? this.$route.query.cta
            : '';

        if (this.hasHeroKey(ctaValue)) {
          return this.heroFlightConfig[ctaValue].image;
        } else {
          return this.heroFlightConfig['default'].image;
        }
      }
      return '';
    },

    getHeroTitle() {
      if (this.heroFlightConfig) {
        const ctaValue = this.$route.query.cta;

        if (this.hasHeroKey(ctaValue)) {
          return this.heroFlightConfig[ctaValue].title;
        } else {
          return this.heroFlightConfig['default'].title;
        }
      }
      return '';
    },

    noFlightCities() {
      return this.configs?.no_flight_cities?.length
        ? this.configs.no_flight_cities
        : null;
    },
  },

  methods: {
    hasHeroKey(ctaValue) {
      return ctaValue && Object.keys(this.heroFlightConfig).includes(ctaValue);
    },
  },
};
</script>
