import qs from 'qs';
import store from '@/store/index';

const isProductionOrSandbox = () => {
  let hostname = getHostname();
  let productionOrSandboxPattern = new RegExp(
    /^(([\w\d]+)(-web)?(.tcp)?.(sbox.)?guestlogix.io)$/
  );
  return productionOrSandboxPattern.test(hostname);
};

const getHostname = () => {
  return window.location.hostname;
};

const getNuxtBaseHostname = () => {
  return window.location.host;
};

const getNuxtBaseHostnameWithProtocol = () => {
  return `${window.location.protocol}//${getNuxtBaseHostname()}`;
};

const redirectToWPNuxt = (path, queryObject) => {
  if (!path.startsWith('/')) path = '/' + path;

  let location = `${getNuxtBaseHostnameWithProtocol()}${path}`;

  if (queryObject) {
    const queryString = qs.stringify(queryObject);
    location += '?' + queryString;
  }

  window.location = location;
};

export default {
  isProductionOrSandbox,
  getHostname,
  getNuxtBaseHostname,
  getNuxtBaseHostnameWithProtocol,
  redirectToWPNuxt,
};
