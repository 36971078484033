import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class LoyaltyRepository extends BaseRepository {
  constructor({ resourceId } = { resourceId: '/' }) {
    super('points', resourceId);
  }

  getWallet(travelerId = this.resourceId) {
    return authAxios.get(
      this.getBaseResourceUrl({
        id: travelerId,
        endpoint: `wallet`,
      })
    );
  }
}
