import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class OrderRepository extends BaseRepository {
  constructor({ resourceId } = { resourceId: '/' }) {
    super('order', resourceId);
  }

  createOrder(
    { amount, travelerId, products } = {
      amount: 0,
      travelerId: null,
      products: [],
    }
  ) {
    const requestData = {
      travelerId,
      amount,
      products,
    };
    return authAxios.post(this.resource, requestData);
  }

  checkOutOrder(
    {
      paymentMethodId,
      save = false,
      discountToken,
      asyncCall = false,
      sourceId,
      paymentMethodType,
    } = {
      paymentMethodId: null,
      save: false,
      discountToken: null,
      asyncCall: false,
    }
  ) {
    const requestData = {
      save,
      paymentMethodId,
      discountToken,
      sourceId,
      paymentMethodType,
    };
    return new Promise((resolve, reject) => {
      authAxios
        .patch(
          this.getBaseResourceUrl({
            id: this.resourceId,
            endpoint: asyncCall ? 'async' : '',
          }),
          requestData
        )
        .then((response) => {
          resolve({
            order: response.data,
            clientConfirmationKey: null,
          });
        })
        .catch((error) => {
          if (error.response.status === 402) {
            resolve({
              order: null,
              clientConfirmationKey:
                error.response.data.errorData.confirmationKey,
            });
          } else {
            reject(error);
          }
        });
    });
  }

  getPaymentMethods(
    { orderId = this.resourceId } = { orderId: this.resourceId }
  ) {
    return authAxios.get(
      this.getBaseResourceUrl({
        id: orderId,
        endpoint: `payment-methods`,
      })
    );
  }

  sendConfirmationEmail(
    { orderId = this.resourceId } = { orderId: this.resourceId }
  ) {
    return authAxios.patch(
      this.getBaseResourceUrl({
        id: orderId,
        endpoint: `ticket`,
      })
    );
  }

  pollOrder({ orderId = this.resourceId } = { orderId: this.resourceId }) {
    return authAxios.get(
      this.getBaseResourceUrl({
        id: orderId,
        endpoint: 'poll',
      })
    );
  }

  getPromo(
    { orderId = this.resourceId, promoCode } = {
      orderId: this.resourceId,
      promoCode: '',
    }
  ) {
    return authAxios.get(
      this.getBaseResourceUrl({
        id: orderId,
        endpoint: `/promo/${encodeURI(promoCode)}`,
      })
    );
  }
}
