import AuthService from './AuthService';

export const authGuard = async (to, from, next) => {
  const authService = new AuthService();

  if (authService.isAuthenticated) {
    return next();
  }

  const successAuthentication = await authService.authenticate();
  if (successAuthentication) {
    next();
  }
};
