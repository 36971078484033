import Axios from 'axios';
import axiosRetry from 'axios-retry';
import localstorageUtils from '@/core/utils/localstorage.js';
import { getHeaders } from '../repositories';
import { SIDECAR_API_PATH } from '../../../sidecar-env';
axiosRetry(Axios, { retries: 3 });

export default class AuthService {
  resource = `${SIDECAR_API_PATH}/api/auth/token`;

  get token() {
    const token = localstorageUtils.getToken();
    if (this.validateToken(token)) {
      return token;
    } else {
      localstorageUtils.removeToken();
      return null;
    }
  }

  set token(tokenObj) {
    localstorageUtils.setToken(tokenObj);
  }

  get isAuthenticated() {
    return this.token !== null;
  }

  validateToken(token) {
    return token !== null && this.validateTokenExpiration(token);
  }

  validateTokenExpiration(token) {
    const expiresDate = new Date(token.expires);
    const today = new Date(Date.now());
    return expiresDate > today;
  }

  async authenticate() {
    const response = await Axios.get(this.resource, {
      headers: getHeaders(),
    });
    this.token = response.data;
    return this.isAuthenticated;
  }
}
