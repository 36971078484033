<template>
  <v-snackbar
    class="alert-information"
    color="var(--v-grey1-base)"
    timeout="7000"
    top
    height="80"
    width="375"
    absolute
    :value="codeShareAlert"
  >
    <span data-cy="product-action-alert-message" class="snack-text black--text">
      {{
        $t('flights.code_share_flight', {
          originCompany: codeShareInfo.codeShareAirlineName,
          shareCompany: codeShareInfo.airlineName,
        })
      }}
    </span>
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        small
        text
        v-bind="attrs"
        @click="closeSnackBar"
        aria-label="Close snackbar"
        title="Close snackbar"
      >
        <v-icon color="#858F9D"> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'CodeShareComponent',
  computed: {
    codeShareAlert() {
      return this.$store.getters['flights/codeShareAlert'];
    },

    codeShareInfo() {
      return this.$store.getters['flights/codeShareInfo'];
    },
  },

  methods: {
    async closeSnackBar() {
      await this.$store.dispatch('flights/setCodeShareAlert', false);
      await this.$store.dispatch('flights/setCodeShareInfo', {
        codeShareAirlineName: '',
        airlineName: '',
      });
    },
  },

  watch: {
    codeShareAlert(val) {
      if (val) {
        setTimeout(() => {
          this.$store.dispatch('flights/setCodeShareAlert', false);
          this.$store.dispatch('flights/setCodeShareInfo', {
            codeShareAirlineName: '',
            airlineName: '',
          });
        }, 7000);
      }
    },
  },
};
</script>
