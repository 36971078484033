import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class TravelerRepository extends BaseRepository {
  constructor({ resourceId } = { resourceId: '/' }) {
    super('traveler', resourceId);
  }

  getFlightBookings(travelerId = this.resourceId) {
    return authAxios.get(
      this.getBaseResourceUrl({ id: travelerId, endpoint: `flightbooking` })
    );
  }

  addFlightBookings({ travelerId = this.resourceId, flights }) {
    const addFlightRequests = flights.map((flight) => {
      return authAxios.post(
        this.getBaseResourceUrl({ id: travelerId, endpoint: `flightbooking` }),
        flight
      );
    });
    return Promise.all(addFlightRequests);
  }

  deleteFlightBooking({ travelerId = this.resourceId, flightId }) {
    return authAxios.delete(
      this.getBaseResourceUrl({
        id: travelerId,
        endpoint: `flightbooking/${flightId}`,
      })
    );
  }

  getSavedCreditCards(travelerId = this.resourceId) {
    return authAxios.get(
      this.getBaseResourceUrl({
        id: travelerId,
        endpoint: `savedPaymentMethods`,
      })
    );
  }

  getPastOrders(travelerId = this.resourceId) {
    return authAxios.get(
      this.getBaseResourceUrl({ id: travelerId, endpoint: `order` }),
      {
        params: {
          skip: 0,
          take: 10000,
        },
      }
    );
  }
}
