<template>
  <v-dialog
    class="date-time-picker-dialog"
    :width="340"
    v-model="showDialog"
    :overlay-opacity="0"
    :fullscreen="true"
    v-if="$vuetify.breakpoint.smAndDown && withMobileDialog"
  >
    <template v-slot:activator="{ on }">
      <v-container fluid class="fill-height py-0" id="DateTimeSelectorMobile">
        <v-row no-gutters class="mx-n3">
          <span :class="labelClass ? labelClass : 'subtitle-label'">
            {{ dateTitle }}
          </span>
        </v-row>
        <v-row no-gutters class="mx-n3">
          <v-text-field
            tabindex="0"
            :height="inputHeight"
            :hide-details="isHideDetails"
            :loading="loading"
            :label="label"
            :value="formattedDatetime"
            :rules="[(v) => !!v || 'Required']"
            v-on="on"
            outlined
            readonly
            :placeholder="inputPlaceholder"
            dense
            :class="[
              'date-time-text',
              { 'short-width': $vuetify.breakpoint.lgAndUp },
            ]"
            aria-labelledby="DateTimeSelectorMobile"
            aria-selected="true"
          >
            <template v-slot:progress>
              <slot name="progress">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  absolute
                  height="2"
                />
              </slot>
            </template>
          </v-text-field>
        </v-row>
      </v-container>
    </template>
    <v-sheet :elevation="0" color="white" class="fill-height">
      <v-tabs fixed-tabs v-model="activeTab">
        <v-tab key="calendar">
          <slot name="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </slot>
        </v-tab>
        <v-tab v-if="timeTab" key="timer" :disabled="dateSelected">
          <slot name="timeIcon">
            <v-icon>mdi-clock-outline</v-icon>
          </slot>
        </v-tab>
        <v-tab-item key="calendar">
          <v-date-picker
            color="primary"
            class="date-picker-custom"
            header-color="white"
            v-model="date"
            :min="minimum"
            full-width
            :locale="userPreferredLanguage"
          />
        </v-tab-item>
        <v-tab-item key="timer">
          <v-time-picker
            ref="timer"
            color="primary"
            header-color="white"
            class="time-picker-custom"
            v-model="time"
            full-width
          />
        </v-tab-item>
      </v-tabs>

      <slot name="actions">
        <v-container
          fluid
          class="pt-0"
          :class="{
            'pr-6 actions-container-sm': $vuetify.breakpoint.smAndDown,
          }"
        >
          <v-row align="start" justify="space-between" no-gutters>
            <v-btn text @click.native="onClear">Clear</v-btn>
            <v-btn
              v-if="displayTimeButton"
              color="primary"
              @click="showTimePicker"
              :disabled="dateSelected"
            >
              {{ $t('set_time') }}
            </v-btn>
            <v-btn
              v-else
              depressed
              :disabled="!date"
              :class="{ primary: date }"
              @click="onApply"
            >
              {{ $t('apply') }}
            </v-btn>
          </v-row>
        </v-container>
      </slot>
    </v-sheet>
  </v-dialog>
  <v-menu
    v-else
    class="date-time-picker-dialog"
    v-model="showDialog"
    :overlay-opacity="0"
    :close-on-content-click="false"
    min-width="290px"
    tabindex="0"
  >
    <template v-slot:activator="{ on }">
      <v-container fluid class="fill-height py-0" id="DateTimeSelectorDesktop">
        <v-row no-gutters class="mx-n3">
          <span :class="labelClass ? labelClass : 'subtitle-label'">
            {{ dateTitle }}
          </span>
        </v-row>
        <v-row no-gutters class="mx-n3">
          <v-text-field
            tabindex="0"
            @focus.native="onFocus"
            @blur.native="onBlur"
            @keyup="showDialog = true"
            @keydown="showDialog = false"
            data-cy="date-time-text"
            :loading="loading"
            :label="label"
            :value="formattedDatetime"
            :rules="[(v) => !!v || 'Required']"
            v-on="on"
            outlined
            readonly
            :placeholder="inputPlaceholder"
            dense
            required
            :hide-details="isHideDetails"
            :height="inputHeight"
            class="date-time-text"
            :class="inputClass"
            aria-labelledby="DateTimeSelectorDesktop"
            aria-selected="true"
          >
            <template v-slot:progress>
              <slot name="progress">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  absolute
                  height="2"
                />
              </slot>
            </template>
          </v-text-field>
        </v-row>
      </v-container>
    </template>
    <v-sheet :elevation="0" color="white" class="fill-height">
      <template v-if="allowTimeSelection">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" v-if="timeTab" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              :noTitle="hasTitle"
              color="primary"
              class="date-picker-custom"
              header-color="white"
              v-model="date"
              :min="minimum"
              :locale="userPreferredLanguage"
            />
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              :noTitle="hasTitle"
              ref="timer"
              color="primary"
              header-color="white"
              class="time-picker-custom"
              v-model="time"
              full-width
            />
          </v-tab-item>
        </v-tabs>
        <slot name="actions">
          <v-container
            fluid
            class="pt-0"
            :class="{
              'pr-6 actions-container-sm': $vuetify.breakpoint.smAndDown,
            }"
          >
            <v-row align="start" justify="space-between" no-gutters>
              <v-btn text @click.native="onClear">Clear</v-btn>
              <v-btn
                data-cy="set-time-button"
                v-if="displayTimeButton"
                color="secondary darken-1"
                @click="showTimePicker"
                :disabled="dateSelected"
              >
                {{ $t('set_time') }}
              </v-btn>
              <v-btn
                depressed
                data-cy="apply-date-time-button"
                v-else
                :disabled="!date"
                :class="{ primary: date }"
                @click="onApply"
              >
                {{ $t('apply') }}
              </v-btn>
            </v-row>
          </v-container>
        </slot>
      </template>

      <template v-else>
        <v-date-picker
          ref="picker"
          :noTitle="hasTitle"
          color="primary"
          class="date-picker-custom"
          header-color="white"
          v-model="date"
          :min="minimum"
          :locale="userPreferredLanguage"
          @input="onApply()"
        />
      </template>
    </v-sheet>
  </v-menu>
</template>

<script>
import LanguageMixins from '@/core/mixins/LanguageMixins';
import moment from 'moment';

const DEFAULT_TIME = '00:00:00';

export default {
  name: 'DateTimeSelector',
  mixins: [LanguageMixins],
  props: {
    allowTimeSelection: {
      type: Boolean,
      default: true,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    inputPlaceholder: {
      type: String,
      default: 'Select Date & Time',
    },
    inputHeight: {
      type: String,
      default: 'auto',
    },
    inputClass: {
      type: String,
      default: '',
    },
    isHideDetails: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    value: {
      default: null,
    },
    timeTab: {
      type: Boolean,
      default: true,
    },
    dateTitle: {
      default: 'Date',
    },
    dateFormat: {
      type: String,
    },
    withMobileDialog: {
      type: Boolean,
      default: true,
    },
    allowPastDates: { type: Boolean, default: true },
  },
  data() {
    return {
      datetime: null,
      activeTab: 0,
      date: '',
      time: DEFAULT_TIME,
      showDialog: false,
    };
  },

  computed: {
    formattedDatetime() {
      return this.selectedDatetime
        ? moment(this.selectedDatetime).format(
            this.dateFormat || this.dateTimeFormat
          )
        : '';
    },

    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + ' ' + this.time;
        if (this.time.length === 5) {
          datetimeString += ':00';
        }
        return moment(datetimeString).toDate();
      } else {
        return null;
      }
    },

    dateTimeFormat() {
      return this.timeTab ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
    },

    displayTimeButton() {
      return this.activeTab === 0 && this.timeTab;
    },

    dateSelected() {
      return !this.date;
    },

    minimum() {
      return this.allowPastDates
        ? undefined
        : moment()
            .format(this.dateTimeFormat)
            .toString();
    },
  },

  methods: {
    onFocus() {
      this.showDialog = true;
    },

    onBlur() {
      this.showDialog = false;
    },

    initDateTime(value) {
      if (value) {
        const formattedDateSegments = moment(value)
          .format(this.dateFormat || this.dateTimeFormat)
          .split(' ');
        if (formattedDateSegments.length > 1) {
          this.time = formattedDateSegments[1];
        }
        this.date = formattedDateSegments[0];
      }
    },

    showTimePicker() {
      this.activeTab = 1;
    },

    onApply() {
      this.resetPicker();
      this.$emit('input', this.selectedDatetime);
    },

    onClear() {
      this.resetPicker();
      this.date = '';
      this.time = DEFAULT_TIME;
      this.showDialog = false;
      this.$emit('input', null);
    },

    resetPicker() {
      this.showDialog = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
  },

  watch: {
    showDialog(val) {
      if (val && !this.allowTimeSelection) {
        this.$nextTick(() => {
          if (!!this.$refs.picker) this.$refs.picker.activePicker = 'DATE';
        });
      }
    },

    value: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.initDateTime(val);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.date-picker-custom {
  &.v-picker.v-card {
    box-shadow: none;
  }

  .v-date-picker-title {
    color: #05090f;
  }
}

.time-picker-custom {
  &.v-picker.v-card {
    box-shadow: none;
  }

  .v-time-picker-title {
    color: #05090f;
  }
}

.actions-container-sm {
  position: absolute;
  bottom: 10px;
}

.short-width .v-input__slot {
  width: 70%;
}

::v-deep .date-time-text .v-input__slot {
  padding: 0 10px !important;
}

.subtitle-label {
  @include font-size(16, 24, 600);
  margin-bottom: 4px;
  display: block;
}

.disabled {
  opacity: 0.5;
}
</style>
