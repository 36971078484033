<template>
  <v-menu
    :bottom="true"
    left
    offset-y
    :nudge-bottom="15"
    :nudge-top="0"
    v-model="isOpen"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        data-cy="language-switcher"
        :class="{
          'dark-background': isWelcomePage,
          'is-open': isOpen,
          'mt-1': $vuetify.breakpoint.lgAndDown,
        }"
        text
        v-bind="attrs"
        v-on="on"
        :small="$vuetify.breakpoint.lgAndDown"
        :color="isWelcomePage ? '#fff' : '#000'"
      >
        <v-icon dark small class="mr-1"> mdi-web </v-icon>
        {{ currentLanguageText }}

        <template v-if="$vuetify.breakpoint.lgAndUp">
          <v-icon right dark small class="language-chevron ml-1">
            mdi-chevron-down
          </v-icon>
        </template>
      </v-btn>
    </template>

    <v-list data-cy="language-switcher-list">
      <v-list-item class="menu-title">{{ $t('select_language') }}</v-list-item>
      <v-list-item
        data-cy="language-switcher-list-item"
        @click="switchUserLocale(lang.locale)"
        v-for="lang in languages"
        :key="lang.locale"
      >
        <v-list-item-title>{{ lang.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import LanguageMixins from '@/core/mixins/LanguageMixins';

export default {
  name: 'LanguageSwitcher',
  mixins: [LanguageMixins],
  props: {
    isWelcomePage: {
      type: Boolean,
      default: false,
    },
    languages: {
      default: () => [
        {
          label: 'English',
          locale: 'en',
          default: true,
        },
      ],
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    currentLanguageText() {
      return this.$i18n.locale.split('-')[0].toUpperCase();
    },
  },
};
</script>

<style scoped lang="scss">
.is-open {
  .language-chevron {
    transform: rotate(180deg);
  }
}
.menu-title {
  font-weight: 600;
  font-size: 16px;
  user-select: none;
}
.dark-background {
  padding: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}
</style>
