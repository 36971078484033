import Vue from 'vue';
import moment from 'moment';

const validFormatDate = (value, type) => {
  const formatterOutput = dateFilter(value, type);
  return formatterOutput !== 'Invalid date' ? formatterOutput : false;
};

export const dateFilter = (value, type) => {
  if (!value) return value;
  return moment(value).format(type);
};

export const dateRangeFilter = (start, end, type) => {
  const startRange = validFormatDate(start, type);
  const endRange = validFormatDate(end, type);

  return startRange && endRange
    ? startRange === endRange
      ? startRange
      : `${startRange} - ${endRange}`
    : 'Invalid date';
};

export default Vue.filter('dateFormat', dateFilter);
