import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

class MapboxService {
  constructor() {
    this.geocodingClient = null;
  }

  init(token) {
    this.geocodingClient = mbxGeocoding({
      accessToken: token,
    });
  }

  getCities(cityName, proximity = null) {
    if (!this.geocodingClient) {
      return Error('Geocoding client not initialized');
    }
    return this.geocodingClient
      .forwardGeocode({
        query: cityName,
        language: [window.localStorage.locale],
        types: ['place'],
        proximity: proximity,
        limit: 10,
      })
      .send()
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return error;
      });
  }
  getCitiesWithoutTypesFilter(cityName, proximity = null) {
    if (!this.geocodingClient) {
      return Error('Geocoding client not initialized');
    }
    return this.geocodingClient
      .forwardGeocode({
        query: cityName,
        language: [window.localStorage.locale],
        proximity: proximity,
        limit: 10,
      })
      .send()
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return error;
      });
  }
  reverseGeocode({ lat, lng }) {
    if (!this.geocodingClient) {
      return Error('Geocoding client not initialized');
    }
    return this.geocodingClient
      .reverseGeocode({
        query: [lng, lat],
        language: [window.localStorage.locale],
      })
      .send()
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return error;
      });
  }

  getLocation(keyword) {
    if (!this.geocodingClient) {
      return Error('Geocoding client not initialized');
    }
    return this.geocodingClient
      .forwardGeocode({
        query: keyword,
        language: [window.localStorage.locale],
      })
      .send()
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return error;
      });
  }
}

export const mapboxService = new MapboxService();
