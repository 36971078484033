import { authAxios } from './index';
import BaseRepository from './BaseRepository';

export default class AdvicaRepository extends BaseRepository {
  constructor({ resourceId } = { resourceId: '/' }) {
    super('health', resourceId);
  }

  getDetails({ advicaId = this.resourceId }) {
    return authAxios.get(this.getBaseResourceUrl({ id: advicaId }));
  }
}
